import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import KeyIcon from "@mui/icons-material/Key";

const HeaderMenuItems = [
  {
    id: 0,
    label: "profile",
    path: "",
    nestedMenu: [],
    icon: <AccountBoxIcon fontSize="small" />,
  },
  {
    id: 1,
    label: "change_password",
    path: "",
    nestedMenu: [],
    icon: <KeyIcon fontSize="small" />,
  },
  {
    id: 2,
    label: "logout",
    path: "/admin",
    icon: <LogoutIcon fontSize="small" />,
  },
];

export default HeaderMenuItems;
