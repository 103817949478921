import { logout } from "./authActions";
import { SET_NOTIF, RESET_NOTIF } from "./types";

export const setNotif = (data) => {
  return async (dispatch, state) => {
    const refreshToken = state().authReducer.refreshToken;
    if (
      data.message === "err_invalid_accesstoken" ||
      data.message === "err_accesstoken_expired"
    ) {
      dispatch(logout({ refreshToken }));
    } else {
      dispatch({
        type: SET_NOTIF,
        payload: data,
      });
    }
  };
};

export const resetNotif = (errorID) => {
  return async (dispatch) => {
    dispatch({
      type: RESET_NOTIF,
      payload: errorID,
    });
  };
};
