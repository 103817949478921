import { SET_NOTIF, RESET_NOTIF } from "../actions/types";

const INITIAL_STATE = {
  notifs: [],
};

const notifReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_NOTIF:
      // console.log(Object.keys(action.payload));

      const newNotifObj = {
        id: state.notifs.length + 1,
        message: action.payload.message,
        variant: action.payload.variant,
      };

      return {
        notifs: [...state.notifs, newNotifObj],
      };
    case RESET_NOTIF:
      const filtereNotifs = state.notifs.filter(
        (item) => item.id !== action.payload
      );
      return {
        notifs: filtereNotifs,
      };

    default:
      return state;
  }
};

export default notifReducer;
