import React, { Fragment, useState, useEffect } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import supplierMenuItems from "../supplierMenuItems";
import { Collapse } from "@mui/material";
import Colors from "../../../assets/Colors";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import { getMyProfile } from "../../../redux/actions/accountAction";
import Badge from "@mui/material/Badge";

function SideMenuLinks(props) {
  const { currentPath } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedMenu, setSelectedMenu] = useState(null);
  const myProfile = useSelector((state) => state.authReducer.myProfile);
  const account = useSelector((state) => state.authReducer.account);
  const badges = useSelector((state) => state.notificationReducer.badges);

  const location = useLocation();
  let navigate = useNavigate();

  const goTo = (menuItem) => {
    if (myProfile?.interests?.length === 0) {
      navigate("/supplier/profile");
    } else if (menuItem.label === selectedMenu) {
      setSelectedMenu(null);
    } else {
      setSelectedMenu(menuItem.label);
      if (!menuItem.nestedMenu.length) {
        navigate(menuItem.path);
      }
    }
  };

  useEffect(() => {
    if (!!account) {
      dispatch(getMyProfile({ uid: account.uid }));
    }
  }, [account]);

  // useEffect(() => {
  //   const curPath = location.pathname.replace(/\/$/, "");

  //   const currentItem = adminMenuItems.find((item) => item.path === curPath);
  //   console.log(location);

  //   console.log(curPath);
  //   console.log(currentItem.label);
  //   setSelectedMenu(currentItem.label);
  // }, [location.pathname]);

  return (
    <List>
      {supplierMenuItems?.map((item, index) => (
        <Fragment key={index}>
          <ListItemButton
            disabled={myProfile?.interests?.length === 0}
            selected={currentPath === item.path}
            onClick={() => goTo(item)}
            sx={{ minHeight: "56px" }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText
              primary={t(item.label)}
              sx={{ color: "text.secondary" }}
            />
            {item.label === "support" && badges?.tickets > 0 && (
              <Badge badgeContent={badges?.tickets} color="secondary" />
            )}

            {item?.nestedMenu?.length > 0 && (
              <ListItemIcon sx={{ justifyContent: "flex-end" }}>
                {selectedMenu === item.label ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </ListItemIcon>
            )}
          </ListItemButton>

          {item?.nestedMenu?.length > 0 ? (
            <Collapse
              in={selectedMenu === item.label}
              timeout="auto"
              unmountOnExit
            >
              {item?.nestedMenu?.map((subItem, index) => (
                <ListItemButton
                  selected={currentPath === subItem.path}
                  key={index}
                  onClick={() => navigate(subItem.path)}
                  sx={{ height: "56px" }}
                >
                  <ListItemText
                    primary={t(subItem.label)}
                    sx={{ color: "text.secondary", pl: "40px" }}
                  />
                </ListItemButton>
              ))}
            </Collapse>
          ) : null}
        </Fragment>
      ))}
    </List>
  );
}

export default SideMenuLinks;
