import { combineReducers } from "redux";
import authReducer from "./reducers/authReducer";
import basicInfoReducer from "./reducers/basicInfoReducer";
import userManagementReducer from "./reducers/userManagementReducer";
import newsReducer from "./reducers/newsReducer";
import ticketReducer from "./reducers/ticketReducer";
import followerReducer from "./reducers/followerReducer";
import activityReducer from "./reducers/activityReducer";
import chatReducer from "./reducers/chatReducer";

import notificationReducer from "./reducers/notificationReducer";

import notifReducer from "./reducers/notifReducer";

const rootReducer = combineReducers({
  authReducer: authReducer,
  notifReducer: notifReducer,
  basicInfoReducer: basicInfoReducer,
  userManagementReducer: userManagementReducer,
  newsReducer: newsReducer,
  ticketReducer: ticketReducer,
  followerReducer: followerReducer,
  activityReducer: activityReducer,
  chatReducer: chatReducer,
  notificationReducer: notificationReducer,
});

export default rootReducer;
