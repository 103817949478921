import { BarItem } from "@nivo/bar";
import {
  GET_FOLLOWERS,
  REMOVE_FOLLOWER,
  CLEAR_FOLLOW_STORE,
} from "../actions/types";

const INITIAL_STATE = {
  followers: {
    list: [],
  },
};

const followerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_FOLLOWERS:
      return {
        ...state,
        followers: action.payload,
      };
    case REMOVE_FOLLOWER:
      const filteredList = state.followers.list.filter(
        (item) => item.uid !== action.payload.uid
      );
      return {
        ...state,
        followers: {
          ...state.followers,
          list: filteredList,
        },
      };
    case CLEAR_FOLLOW_STORE:
      return {
        followers: {
          list: [],
        },
      };

    default:
      return state;
  }
};

export default followerReducer;
