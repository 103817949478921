import {
  GET_ACTIVITY_DETAIL,
  GET_ACTIVITY_LIST,
  CREATE_ACTIVITY,
  EDIT_ACTIVITY,
  DELETE_ACTIVITY,
  INVITE_MEMBER,
  REMOVE_MEMBER,
  GET_MEMBERS,
  CLEAR_ACTIVITY_STORE,
  CLEAR_CURRENT_ACTIVITY_DETAIL,
} from "../actions/types";

const INITIAL_STATE = {
  activities: [],
  activityDetail: null,
  members: { list: [] },
};

const activityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ACTIVITY_LIST:
      return {
        ...state,
        activities: action.payload,
      };
    case GET_ACTIVITY_DETAIL:
      return {
        ...state,
        activityDetail: action.payload,
      };
    case GET_MEMBERS:
      return {
        ...state,
        members: action.payload,
      };
    case REMOVE_MEMBER:
      console.log(action.payload);
      const updatedMembers = state.members.list.filter(
        (item) => item.uid !== action.payload
      );
      return {
        ...state,
        members: {
          ...state.members,
          list: updatedMembers,
        },
      };
    case CLEAR_CURRENT_ACTIVITY_DETAIL:
      return {
        ...state,
        activityDetail: null,
        members: { list: [] },
      };
    case CLEAR_ACTIVITY_STORE:
      return {
        activities: [],
        activityDetail: null,
        members: { list: [] },
      };
    default:
      return state;
  }
};

export default activityReducer;
