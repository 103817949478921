import {
  LOGIN,
  LOGOUT,
  REFRESH_TOKEN,
  UPDATE_MY_PROFILE,
  GET_MY_PROFILE,
  CLEAR_AUTH_STORE,
} from "../actions/types";

const INITIAL_STATE = {
  myProfile: null,
  refreshToken: null,
  accessToken: null,
  setting: null,
  account: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        account: action.payload.user,
        refreshToken: action.payload.refreshToken,
        accessToken: action.payload.accessToken,
      };
    case UPDATE_MY_PROFILE:
      const profileCopy = state.account;
      const updates = Object.keys(action.payload);
      console.log(updates);
      console.log(action.payload);
      console.log(profileCopy);
      updates.forEach((item) => (profileCopy[item] = action.payload[item]));
      return state;

    case GET_MY_PROFILE:
      // localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        myProfile: action.payload,
      };

    case REFRESH_TOKEN:
      // localStorage.setItem("token", action.payload.token);
      return {
        ...state,
        account: action.payload.user,
        accessToken: action.payload.accessToken,
      };
    case CLEAR_AUTH_STORE:
      return {
        ...state,
        myProfile: null,
        refreshToken: null,
        accessToken: null,
        account: null,
      };
    default:
      return state;
  }
};

export default userReducer;
