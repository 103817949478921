const Colors = {
  PRIMARY: "#303030", //#336699
  // PRIMARY_MID: '#6F93B7',
  // PRIMARY_LIGHT: '#98B2CC',
  SECONDARY: "#FA444D", //#
  LIGHT_SECONDARY: "#eaa4a4",
  TEXT_LIGHT: "#bfbfbf",
  TEXT_LIGHT_RGB: "rgb(191,191,191)",
  TEXT_DARK: "#757575",
  TEXT_REGULAR: "#666666",
  TEXT_MEDIUM: "#757575",
  BACKGROUND: "#F4F4F4",
  BACKGROUND2: "#F5F5F5",
  YELLOW: "rgb(252,194,0)",
  GREEN: "rgb(127,176,105)",
  GREEN_25: "rgba(127, 176, 105, 0.25)",
  WHITE: "#fff",
  BLACK: "#333333",
  RED: "rgb(255, 29, 0)",
  RED_25: "rgb(255, 29, 0, 0.15)",
  BLUE: "#42BFDD",
  PINK: "#F9B9B7",
  ORANGE: "#F58F29",
  MODAL_BACK: "rgba(61, 61, 61, 0.6)",
  BLACK_50: "rgba(0, 0, 0, 0.5)",
  BLACK_35: "rgba(0, 0, 0, 0.35)",
  BLACK_15: "rgba(0, 0, 0, 0.15)",
  BLACK_75: "rgba(0, 0, 0, 0.75)",
  BLACK_05: "rgba(0, 0, 0, 0.05)",
  LIGHT_GRAT: "#eaeaea",
  WHITE_25: "rgba(256,256,256,0.50)",
  WHITE_50: "rgba(256,256,256,0.70)",
  WHITE_75: "rgba(256,256,256,0.85)",
  TRANSPARENT: "rgba(256,256,256,0)",
};

export default Colors;
