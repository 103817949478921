import React from "react";
import {  useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import AutoLogout from "../basic/AutoLogout";

const PrivateRoute = ({ children, role }) => {
  const account = useSelector((state) => state.authReducer.account);
  let location = useLocation();


  if (!account) {
    return <Navigate to="/" replace state={{ path: location.pathname }} />;
  } else if (role === account.utype) {
    return <AutoLogout>{children}</AutoLogout>;
  } else {

    return <Navigate to="/" />;
  }
};

export default PrivateRoute;
