import React, { Component, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import DrawerLinks from "./DrawerLinks";
import logo from "../../../assets/images/top-logo.png";
import Colors from "../../../assets/Colors";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { Avatar, Button, Stack } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// import { logout } from "../../../redux/actions/authActions";
import MenuIcon from "@mui/icons-material/Menu";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import HeaderMenuItems from "./HeaderLinks";
import { logout } from "../../../redux/actions/authActions";
import { REACT_APP_API_URL } from "../../../assets/constants";
import AdminMyProfileModal from "../../../components/modals/AdminMyProfileModal";
import ChangePasswordModal from "../../../components/modals/ChangePasswordModal";

const drawerWidth = 288;

const Header = () => {
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const account = useSelector((state) => state.authReducer.account);
  const refreshToken = useSelector((state) => state.authReducer.refreshToken);
  const [openModal, setOpenModal] = useState(false);
  const [passwordModal, setPasswordModal] = React.useState(false);

  const toggleDrawer = (anchor, open, fb) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(open);
    if (fb) {
      fb();
    }
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const actionHandler = (type, path) => {
    if (type === "logout") {
      dispatch(logout({ refreshToken }));
    } else {
      handleCloseUserMenu();
      if (type === "profile") {
        navigate("/supplier/profile");
      } else {
        setPasswordModal(true);
      }
      // navigate(path);
    }
  };

  return (
    <AppBar
      position="fixed"
      color="background"
      elevation={0}
      sx={{
        width: { md: `calc(100% - ${drawerWidth}px)`, xs: "auto" },
        // ml: { sm: `${drawerWidth}px`, md: 1 },
        // mx: 1,
        mt: 1,
        // ml: 1,
        right: "8px",
        left: { md: `${drawerWidth - 8}px`, xs: "8px" },
        // left: { sm: 0, md: "auto" },
        // width: "90vw",
        borderRadius: "5px",
        boxShadow: "5px 0px 30px rgba(0, 0, 0, 0.14)",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar sx={{ width: "100%" }}>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={toggleDrawer("top", true)}
              sx={{ color: "text.secondary", p: 0 }}
            >
              <MenuIcon />
            </IconButton>
            <SwipeableDrawer
              anchor={"left"}
              open={openDrawer}
              onClose={toggleDrawer("left", false)}
              onOpen={toggleDrawer("left", true)}
            >
              <DrawerLinks
                toggleDrawer={toggleDrawer}
                currentPath={location.pathname}
              />
            </SwipeableDrawer>
          </Box>
          <Typography sx={{ display: { xs: "none", md: "flex" } }}>
            {t("admin")}
          </Typography>

          <Stack
            direction={"row"}
            spacing={1}
            sx={{
              display: { xs: "flex", md: "none" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              onClick={handleOpenUserMenu}
              alt="avatar image"
              src={account?.pic ? `${REACT_APP_API_URL}/${account?.pic}` : ""}
            />
          </Stack>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Stack
              direction={"row"}
              spacing={1}
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar
                alt="avatar image"
                src={account?.pic ? `${REACT_APP_API_URL}/${account?.pic}` : ""}
              />
              <Box sx={{ flexGrow: 0 }}>
                <Button
                  onClick={handleOpenUserMenu}
                  variant="text"
                  color="text"
                  // endIcon={
                  //   <FontAwesomeIcon
                  //     icon={["far", "angle-down"]}
                  //     color={Colors.text.secondary}
                  //   />
                  // }
                >
                  {account?.fullName}
                </Button>

                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {HeaderMenuItems.map((item, index) => (
                    <MenuItem
                      key={index}
                      // component={Link}
                      // to={item.path}
                      onClick={() => actionHandler(item.label, item.path)}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText>{t(item.label)}</ListItemText>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Stack>
          </Box>
        </Toolbar>
        <AdminMyProfileModal
          openModal={openModal}
          setCloseModal={() => setOpenModal(false)}
        />

        <ChangePasswordModal
          openModal={passwordModal}
          setOpenModal={setPasswordModal}
        />
      </Container>
    </AppBar>
  );
};
export default Header;
