import React, { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import {
  getMyProfile,
  updateMyProfile,
} from "../../redux/actions/accountAction";
import ProfileAvatar from "../../components/basic/ProfileAvatar";
import { useForm, Controller } from "react-hook-form";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import CloseVerificationModal from "./CloseVerificationModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  // right: "20%",
  transform: "translate(-50%, -50%)",
  width: { xs: "85%", md: "50%" },
  bgcolor: "background.paper",
  borderRadius: "8px",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 3,
};

const AdminMyProfileModal = (props) => {
  const { openModal, setCloseModal } = props;
  const { t } = useTranslation();
  const myProfile = useSelector((state) => state.authReducer.myProfile);
  const account = useSelector((state) => state.authReducer.account);

  const dispatch = useDispatch();
  const [confirmClose, setConfirmClose] = useState(false);

  const { handleSubmit, reset, control, setValue } = useForm({
    defaultValues: {
      email: "",
      fullName: "",
      username: "",
    },
  });

  useEffect(() => {
    if (myProfile) {
      setValue("email", myProfile.email);
      setValue("fullName", myProfile.fullName);
      setValue("username", myProfile.username);
    }
  }, [myProfile]);

  useEffect(() => {
    dispatch(getMyProfile({ uid: account.uid }));
  }, []);

  //   const onChange = (e, field) => {
  //     setForm({
  //       ...form,
  //       [field]: e.target.value,
  //     });
  //   };

  const submit = (data) => {
    const dataObj = {
      fullName: data.fullName,
      username: data.username,
    };
    dispatch(updateMyProfile(dataObj)).then(() => setCloseModal());
  };

  const updateProfileAvatar = (avatarURL) => {
    dispatch(updateMyProfile({ pic: avatarURL, fullName: myProfile.fullName }));
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onClose={() => setConfirmClose(true)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" sx={{ color: "text.main", mb: "20px" }}>
              {t("profile")}
            </Typography>
            <IconButton onClick={() => setConfirmClose(true)}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <ProfileAvatar
                avatarPath={myProfile?.pic}
                updateProfileAvatar={updateProfileAvatar}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4} sx={{ mt: 1 }}>
            <Grid item xs={12} md={6}>
              <Controller
                name={"email"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label={t("email")}
                    variant="outlined"
                    value={value}
                    type={"email"}
                    fullWidth
                    disabled
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name={"username"}
                control={control}
                rules={{
                  required: true,
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label={t("username")}
                    required
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                    error={error}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name={"fullName"}
                control={control}
                rules={{
                  required: true,
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    label={t("full_name")}
                    variant="outlined"
                    value={value}
                    onChange={onChange}
                    error={error}
                    helperText={error?.message}
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 4 }}>
            <Grid item xs={12} md={6}>
              <Button
                onClick={handleSubmit(submit)}
                disableElevation
                variant="contained"
                size="large"
                sx={{ width: "151px", height: "48px" }}
              >
                {t("confirm")}
              </Button>
            </Grid>
          </Grid>
          <CloseVerificationModal
            openModal={confirmClose}
            closeModal={() => setConfirmClose(false)}
            acceptButton={setCloseModal}
          />
        </Box>
      </Fade>
    </Modal>
  );
};

export default AdminMyProfileModal;
