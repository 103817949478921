import GroupIcon from "@mui/icons-material/Group";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CelebrationIcon from "@mui/icons-material/Celebration";
import FeedIcon from "@mui/icons-material/Feed";
import EmailIcon from "@mui/icons-material/Email";
import SupportIcon from "@mui/icons-material/Support";
import ListAltIcon from "@mui/icons-material/ListAlt";

const supplierMenuItems = [
  {
    id: 0,
    label: "dashboard",
    path: "/supplier",
    nestedMenu: [],
    icon: <DashboardIcon />,
  },
  {
    id: 1,
    label: "followers",
    path: "/supplier/followers",
    nestedMenu: [],
    icon: <GroupIcon />,
  },

  {
    id: 2,
    label: "activities",
    path: "/supplier/activities",
    nestedMenu: [],
    icon: <CelebrationIcon />,
  },
  {
    id: 3,
    label: "news",
    path: "/supplier/news",
    nestedMenu: [],
    icon: <FeedIcon />,
  },
  {
    id: 4,
    label: "reports",
    path: "/supplier/reports",
    nestedMenu: [],
    icon: <ListAltIcon />,
  },
  {
    id: 5,
    label: "invitations",
    path: "/supplier/invitation",
    nestedMenu: [],
    icon: <EmailIcon />,
  },
  {
    id: 6,
    label: "support",
    path: "/supplier/support",
    nestedMenu: [],
    icon: <SupportIcon />,
  },
];

export default supplierMenuItems;
