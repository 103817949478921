import {
  GET_BADGES,
  GET_NOTIFICATIONS,
  CLEAR_NOTIFICATION_STORE,
} from "../actions/types";

const INITIAL_STATE = {
  notifications: { list: [] },
  highFives: { list: [] },
  badges: null,
};

const notificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case GET_BADGES:
      return {
        ...state,
        badges: action.payload,
      };

    case CLEAR_NOTIFICATION_STORE:
      return {
        notifications: { list: [] },
        highFives: { list: [] },
        badges: null,
      };

    default:
      return state;
  }
};

export default notificationReducer;
