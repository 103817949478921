import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useTranslation } from "react-i18next";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "85%", md: "30%" },
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 3,
};

function CloseVerificationModal(props) {
  const { t } = useTranslation();
  const { openModal, closeModal, acceptButton } = props;



  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ color: "text.main" }}
            >
              {t("close_form_title")}
            </Typography>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              mt: 1,
              mb: 3,
              justifyContent: "flex-start",
            }}
          >
            <Typography
              id="transition-modal-title"
              variant="p"
              sx={{ color: "text.main" }}
            >
              {t("close_form_description")}
            </Typography>
          </Box>

          <Stack direction={"row"} spacing={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => closeModal(acceptButton())}
              disableElevation
              size="large"
            >
              {t("yes_close_the_form")}
            </Button>

            <Button
              variant="outlined"
              color="primary"
              onClick={closeModal}
              disableElevation
              size="large"
            >
              {t("cancel")}
            </Button>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
}

export default CloseVerificationModal;
