export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const UPDATE_MY_PROFILE = "UPDATE_MY_PROFILE";
export const GET_MY_PROFILE = "GET_MY_PROFILE";

export const GET_INITIAL = "GET_INITIAL";
export const GET_CONFIGS = "GET_CONFIGS";
export const SET_CONFIGS = "SET_CONFIGS";

export const SET_NOTIF = "SET_NOTIF";
export const RESET_NOTIF = "RESET_NOTIF";

export const GET_INTEREST_GROUPS = "GET_INTEREST_GROUPS";
export const CREATE_INTEREST_GROUPS = "CREATE_INTEREST_GROUPS";
export const UPDATE_INTEREST_GROUPS = "UPDATE_INTEREST_GROUPS";
export const DELETE_INTEREST_GROUPS = "DELETE_INTEREST_GROUPS";

export const GET_INTEREST = "GET_INTEREST";
export const CREATE_INTEREST = "CREATE_INTEREST";
export const UPDATE_INTEREST = "UPDATE_INTEREST";
export const DELETE_INTEREST = "DELETE_INTEREST";
export const GET_CITY_LIST = "GET_CITY_LIST";

// export const UPDATE_USER = "UPDATE_USER";
// export const DEACTIVATE_MY_ACCOUNT = "DEACTIVATE_MY_ACCOUNT";
// export const DELETE_USER = "DELETE_USER";
// export const UPDATE_AVATAR = "UPDATE_AVATAR";
// export const UPLOAD_AVATAR = "UPLOAD_AVATAR";

// User management by admin
export const GET_INDIVIDUALS = "GET_INDIVIDUALS";
export const GET_SUPPLIERS = "GET_SUPPLIERS";
export const GET_ADMINS = "GET_ADMINS";
export const GET_USER_DETAIL = "GET_USER_DETAIL";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";

export const CREATE_NEWS = "CREATE_NEWS";
export const UPDATE_NEWS = "UPDATE_NEWS";
export const DELETE_NEWS = "DELETE_NEWS";
export const GET_ADMIN_NEWS = "GET_ADMIN_NEWS";
export const GET_SUPPLIER_NEWS = "GET_SUPPLIER_NEWS";
export const GET_NEWS_DETAIL = "GET_NEWS_DETAIL";

export const CREATE_TICKET = "CREATE_TICKET";
export const CREATE_TICKET_REPLY = "CREATE_TICKET_REPLY";
export const UPDATE_TICKET_STATUS = "UPDATE_TICKET_STATUS";
export const GET_TICKETS = "GET_TICKETS";
export const GET_TICKET_MESSAGES = "GET_TICKET_MESSAGES";
export const DELETE_TICKET = "DELETE_TICKET";

export const GET_FOLLOWERS = "GET_FOLLOWERS";
export const REMOVE_FOLLOWER = "REMOVE_FOLLOWER";

export const CREATE_ACTIVITY = "CREATE_ACTIVITY";
export const EDIT_ACTIVITY = "EDIT_ACTIVITY";
export const DELETE_ACTIVITY = "DELETE_ACTIVITY";
export const GET_ACTIVITY_LIST = "GET_ACTIVITY_LIST";
export const GET_ACTIVITY_DETAIL = "GET_ACTIVITY_DETAIL";
export const GET_ACTIVITY_MEMBER = "GET_ACTIVITY_MEMBER";
export const INVITE_MEMBER = "INVITE_MEMBER";
export const REMOVE_MEMBER = "REMOVE_MEMBER";
export const GET_MEMBERS = "GET_MEMBERS";
export const CLEAR_CURRENT_ACTIVITY_DETAIL = "CLEAR_CURRENT_ACTIVITY_DETAIL";

// Notifications
export const GET_BADGES = "GET_BADGES";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";

// Logout
export const CLEAR_ACTIVITY_STORE = "CLEAR_ACTIVITY_STORE";
export const CLEAR_CHAT_STORE = "CLEAR_CHAT_STORE";
export const CLEAR_ALERT_STORE = "CLEAR_ALERT_STORE";
export const CLEAR_FOLLOW_STORE = "CLEAR_FOLLOW_STORE";
export const CLEAN_USER_MANAGEMENT_STORE = "CLEAN_USER_MANAGEMENT_STORE";
export const CLEAR_BASIC_INFO_STORE = "CLEAR_BASIC_INFO_STORE";
export const CLEAR_NEWS_STORE = "CLEAR_NEWS_STORE";
export const CLEAR_AUTH_STORE = "CLEAR_AUTH_STORE";
export const CLEAR_TICKET_STORE = "CLEAR_TICKET_STORE";
export const CLEAR_NOTIFICATION_STORE = "CLEAR_NOTIFICATION_STORE";
export const CLEAR_CURRENT_USER_DETAIL = "CLEAR_CURRENT_USER_DETAIL";

export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";

// Chat
export const GET_CHAT_LIST = "GET_CHAT_LIST";
export const GET_CHAT_LIST_LIVE = "GET_CHAT_LIST_LIVE";
export const GET_MESSAGE_HISTORY = "GET_MESSAGE_HISTORY";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const GET_MESSAGE = "GET_MESSAGE";
export const SET_CURRENT_CHAT_ID = "SET_CURRENT_CHAT_ID";
export const SET_MUTE = "SET_MUTE";
export const SET_DISABLE = "SET_DISABLE";
export const SET_SEEN = "SET_SEEN";
