import {
  GET_INTEREST,
  CREATE_INTEREST,
  UPDATE_INTEREST,
  DELETE_INTEREST,
  GET_INTEREST_GROUPS,
  CREATE_INTEREST_GROUPS,
  UPDATE_INTEREST_GROUPS,
  DELETE_INTEREST_GROUPS,
  GET_CITY_LIST,
  GET_CONFIGS,
  SET_CONFIGS,
  GET_INITIAL,
  CLEAR_BASIC_INFO_STORE,
  GET_DASHBOARD_DATA,
} from "../actions/types";

const INITIAL_STATE = {
  configs: [],
  sysLang: [],
  initialInterestGroups: [],
  initialInterests: [],
  languages: [],
  countries: [],
  cities: [],
  interests: [],
  interestGroups: [],
  dashboardData: null,
};

const basicInfoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Initials
    case GET_INITIAL:
      return {
        ...state,
        configs: action.payload.config,
        sysLang: action.payload.sysLang,
        initialInterestGroups: action.payload.interestGroup,
        initialInterests: action.payload.interest,
        languages: action.payload.language,
        countries: action.payload.country,
      };
    case GET_CONFIGS:
    case SET_CONFIGS:
      return {
        ...state,
        configs: action.payload,
      };
    // Interests
    case GET_INTEREST:
      return {
        ...state,
        interests: action.payload,
      };
    case CREATE_INTEREST:
      return {
        ...state,
        interests: [...state.interests, action.payload],
      };

    case GET_CITY_LIST:
      return {
        ...state,
        cities: action.payload,
      };
    case UPDATE_INTEREST:
      const interestsCopy = state.interests;
      const updates = Object.keys(action.payload);
      updates.forEach((item) => (interestsCopy[item] = action.payload[item]));
      return state;
    case DELETE_INTEREST:
      const filteredList = state.interests.filter(
        (item) => item.id !== action.payload.id
      );
      return {
        ...state,
        interests: filteredList,
      };

    // Interest groups
    case GET_INTEREST_GROUPS:
      return {
        ...state,
        interestGroups: action.payload,
      };
    case CREATE_INTEREST_GROUPS:
      return {
        ...state,
        interestGroups: [...state.interestGroups, action.payload],
      };
    case UPDATE_INTEREST_GROUPS:
      const interestGroupsCopy = state.interestGroups;
      const updateFields = Object.keys(action.payload);
      updateFields.forEach(
        (item) => (interestGroupsCopy[item] = action.payload[item])
      );
      return state;
    case DELETE_INTEREST_GROUPS:
      const filteredGroup = state.interestGroups.filter(
        (item) => item.id !== action.payload.id
      );
      return {
        ...state,
        interestGroups: filteredGroup,
      };

    case GET_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: action.payload,
      };

    case CLEAR_BASIC_INFO_STORE:
      return {
        configs: [],
        sysLang: [],
        initialInterestGroups: [],
        initialInterests: [],
        languages: [],
        countries: [],
        cities: [],
        interests: [],
        interestGroups: [],
        dashboardData: null,
      };
    default:
      return state;
  }
};

export default basicInfoReducer;
