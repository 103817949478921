import { GET_MY_PROFILE, UPDATE_MY_PROFILE } from "./types";
import axios from "axios";
import { setNotif } from "./notifActions";
import { REACT_APP_API_URL } from "../../assets/constants";

// Update my profile
export const updateMyProfile = (data) => {
  return async (dispatch, state) => {
    const token = state().authReducer.accessToken;

    return axios({
      url: `${REACT_APP_API_URL}/user/profile-set`,
      method: "POST",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        dispatch({
          type: UPDATE_MY_PROFILE,
          payload: data,
        });
        dispatch(setNotif({ message: response.data.msg, variant: "success" }));
        return Promise.resolve(response.data);
      })
      .catch((error, res) => {
        console.log(error);
        dispatch(
          setNotif({ message: error.response.data.err, variant: "error" })
        );
        return Promise.reject(error.response.data.err);
      });
  };
};

export const getMyProfile = (data) => {
  return async (dispatch, state) => {
    const token = state().authReducer.accessToken;
    return axios({
      url: `${REACT_APP_API_URL}/user/profile-get`,
      method: "POST",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        dispatch({
          type: GET_MY_PROFILE,
          payload: response.data,
        });
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        dispatch(
          setNotif({ message: error.response.data.err, variant: "error" })
        );
        return Promise.reject(error.response.data.err);
      });
  };
};

// Update my profile
export const uploadAvatar = (data) => {
  return async (dispatch, state) => {
    const token = state().authReducer.accessToken;

    return axios({
      url: `${REACT_APP_API_URL}/public/upload-pic`,
      method: "POST",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        // dispatch(updateMyProfile({ pic: response.data.picUrl, fullName }));
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        // console.log(1);
        // dispatch(
        //   setNotif({ message: error.response.data.err, variant: "error" })
        // );
        return Promise.reject(error.response.data.err);
      });
  };
};
