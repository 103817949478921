import { createTheme } from "@mui/material";

const myTheme = createTheme({
  palette: {
    primary: {
      main: "#303030",
    },
    secondary: {
      main: "#FA444D",
      light: "#eaa4a4",
    },
    text: {
      main: "#666666",
      light: "#bfbfbf",
      disabled: "#9E9E9E",
      medium: "#757575",
      dark: "#757575",
    },
    success: {
      main: "#4CAF50",
      border: "#A5D7A7",
      text: "#ffffff",
    },
    info: {
      main: "#2196F3",
      dark: "#0B79D0",
      light: "#64B6F7",
      textDark: "#0D3C61",
      lightBg: "#E9F5FE",
      border: "#8FCAF9",
      text: "#ffffff",
    },
    warning: {
      main: "#FF9800",
      border: "#FFCB80",
      text: "#211400",
    },
    error: {
      main: "#F44336",
      border: "#FAA19B",
    },
    background: {
      default: "#F7F7F7",
      light: "#fffff",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "40px",
          textTransform: "none",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#007DFF",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#007DFF",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: "#007DFF",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            color: "#007DFF",
          },
        },
      },
    },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     root: {
    //       borderRadius: "30px !important",
    //     },
    //   },
    // },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "30px !important",

          "&.MuiFormLabel-root": {
            color: "#007DFF",
          },
          // "&.Mui-focused": {
          //   color: "#007DFF",
          //   borderColor: "#007DFF",
          // },
          // "&.Mui-focused fieldset": {
          //   border: "2px solid red", // focus
          // },
          // "& .MuiOutlinedInput-root": {
          //   "& fieldset": {
          //     borderColor: "rgba(0, 0, 0, 0.23)", // default
          //   },
          //   "&.Mui-focused fieldset": {
          //     border: "2px solid red", // customized
          //   },
          // },
        },
      },
    },
    // .css-7twp17-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root.Mui-focused fieldset
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderRadius: "30px",
            },
            "&.Mui-focused fieldset": {
              color: "#666666",
              borderColor: "#666666", // customized
            },
          },
          "&.MuiFormLabel-root": {
            color: "#007DFF",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          // "&.Mui-focused fieldset": {
          //   color: "#007DFF",
          // },
          "&.MuiFormLabel-root": {
            "&.Mui-focused": {
              color: "#666666",
              borderColor: "#666666", // customized
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: "#757575",
          paddingTop: "8px",
          paddingBottom: "8px",
          "&.MuiTableCell-root": {
            "&.MuiTableCell-body": {
              color: "#757575",
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.MuiTableContainer-root": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: "40px",

          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              color: "#007DFF",
              borderColor: "#007DFF", // customized
            },
          },
        },
        select: {
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused ": {
              color: "#007DFF",
              borderColor: "#007DFF", // customized
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused MuiOutlinedInput-root": {
            color: "#2196F3",
            borderColor: "#2196F3", // customized
          },
        },
        // focused: {
        //   borderColor: "#2196F3",
        // },
      },
    },
    MuiDropzoneArea: {
      styleOverrides: {
        root: {
          color: "#757575",
          width: "60%",
          "& .MuiDropzoneArea-root": {
            color: "#757575",
            width: "60%",
          },
        },
      },
    },
    MUIRichTextEditor: {
      styleOverrides: {
        editor: {
          padding: "15px",
          height: "300px",
          maxHeight: "300px",
          overflow: "auto",
          border: "1px solid #BDBDBD",
          borderRadius: "20px",
        },
        editorContainer: {
          height: "300px",
        },
        placeHolder: {
          paddingTop: "15px",
          paddingLeft: "15px",
          width: "100%",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: "30px",
          height: "48px",
        },
      },
    },
  },
});

export default myTheme;
