import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import { persistStore, persistReducer } from "redux-persist";
import rootReducer from "./rootReducer";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

// // Simple redux
// const middleware = applyMiddleware(thunk);
// export const store = createStore(rootReducer, composeWithDevTools(middleware));

const persistConfig = {
  // Root?
  key: "root",
  // Storage Method (React Native)
  storage: storage,
  // Whitelist (Save Specific Reducers)
  whitelist: ["authReducer"],
  // Blacklist (Don't Save Specific Reducers)
  blacklist: [],
};

const middleware = applyMiddleware(thunk);

// Redux: Store

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(middleware)
);

export const persistor = persistStore(store);
