import React, { useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    border: 0,
    "&.Mui-disabled": {
      border: 0,
    },
    "&:not(:first-of-type)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-of-type": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

function LanguageButton() {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState("en");

  const handleChange = (event, newLang) => {
    const lang = newLang === 1 ? "en" : "sv";
    setLang(lang);
    i18n.changeLanguage(lang);
  };

  const children = [
    <ToggleButton selected={i18n.languages[0] === "en"} value={1} key={1}>
      🇬🇧
    </ToggleButton>,
    <ToggleButton selected={i18n.languages[0] === "sv"} value={2} key={2}>
      🇸🇪
    </ToggleButton>,
  ];

  const control = {
    value: lang,
    onChange: handleChange,
    exclusive: true,
  };

  return (
    <Box>
      <StyledToggleButtonGroup {...control}>{children}</StyledToggleButtonGroup>
    </Box>
  );
}

export default LanguageButton;
