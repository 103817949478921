import React, { useState } from "react";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { changePassword } from "../../redux/actions/authActions";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  // right: "20%",
  transform: "translate(-50%, -50%)",
  width: { xs: "85%", md: "30%" },
  bgcolor: "background.paper",
  borderRadius: "8px",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 3,
};

function ChangePasswordModal(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { handleSubmit, reset, control } = useForm({
    defaultValues: {
      currentPass: "",
      newPass: "",
    },
  });
  const { openModal, setOpenModal } = props;
  const [showPassword, setShowPassword] = useState(false);

  const submitForm = (data) => {
    dispatch(changePassword(data)).finally(() => {
      reset();
      setOpenModal(false);
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onClose={() => setOpenModal(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ color: "text.main" }}
            >
              {t("change_password")}
            </Typography>
            <IconButton onClick={() => setOpenModal(false)}>
              <CloseIcon />
            </IconButton>
          </Box>

          <FormControl component="fieldset" sx={{ width: "100%" }}>
            <Stack spacing={3} direction={"column"} sx={{ my: 4 }}>
              <Controller
                name={"currentPass"}
                control={control}
                rules={{
                  required: true,
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormControl variant="outlined">
                    <InputLabel
                      error={!!error}
                      htmlFor="outlined-adornment-password"
                    >
                      {t("current_password")}
                    </InputLabel>
                    <OutlinedInput
                      label={t("new_password")}
                      // variant="outlined"
                      value={value}
                      fullWidth={true}
                      type={showPassword ? "text" : "password"}
                      onChange={onChange}
                      error={!!error}
                      // helperText={error?.message}
                      color={"primary"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                )}
              />

              <Controller
                name={"newPass"}
                control={control}
                rules={{
                  required: true,
                  minLength: {
                    value: 8,
                    message: t("password_error_1"),
                  },
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/,
                    message: t("password_error_2"),
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormControl variant="outlined">
                    <InputLabel
                      error={!!error}
                      htmlFor="outlined-adornment-password"
                    >
                      {t("new_password")}
                    </InputLabel>
                    <OutlinedInput
                      label={t("new_password")}
                      // variant="outlined"
                      value={value}
                      fullWidth={true}
                      type={showPassword ? "text" : "password"}
                      onChange={onChange}
                      error={!!error}
                      // helperText={error?.message}
                      color={"primary"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {error?.message && (
                      <FormHelperText error={!!error}>
                        {error?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Stack>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(submitForm)}
            disableElevation
            size="large"
          >
            {t("change")}
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
}

export default ChangePasswordModal;
