import { Box, Button, Typography } from "@mui/material";
import React from "react";
import image404 from "../assets/images/404.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const { t } = useTranslation();
  let navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
        // pt: "5%",
      }}
    >
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={image404} alt="Page not found" width={"60%"} />
      </Box>

      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={image404} alt="Page not found" width={"60%"} />
      </Box>
      <Typography
        variant="h4"
        component="div"
        sx={{
          textAlign: { xs: "center", md: "inherit" },
          color: "secondary.main",
          my: 3,
        }}
      >
        {t("page_not_found")}
      </Typography>
      <Button
        onClick={() => navigate(-1)} // go back
        disableElevation
        variant="contained"
        size="large"
        sx={{ width: "151px" }}
      >
        {t("go_back")}
      </Button>
    </Box>
  );
}

export default NotFound;
