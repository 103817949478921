import * as React from "react";
import Box from "@mui/material/Box";

import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import { Outlet } from "react-router-dom";
import Header from "./header/Header";
import SideMenu from "./sideMenu/SideMenu";
import { Container } from "@mui/material";
const drawerWidth = 288;

const SupplierLayout = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <Header />
      <SideMenu />

      <Box
        // flex={1}
        flexDirection="column"
        display="flex"
        sx={{
          flexGrow: 1,
          p: 3,
          // m: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          mt: 10,
          // backgroundColor: "white",
          // borderRadius: "5px",
          right: 0,
          // overflow: "auto",
          height: `calc(100vh - 90px)`,

          // boxShadow: "5px 0px 30px rgba(0, 0, 0, 0.14)",
        }}
      >
        {/* <Toolbar /> */}

        <Outlet />
      </Box>
    </Box>
  );
};

export default SupplierLayout;
