import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  updateMyProfile,
  uploadAvatar,
} from "../../redux/actions/accountAction";
import CircularProgress from "@mui/material/CircularProgress";
import convertToBase64 from "../../utils/convertToBase64";
import { REACT_APP_API_URL } from "../../assets/constants";
import CompanyPlaceholder from "../../assets/images/company.png";
import ActivityPlaceholder from "../../assets/images/activity.png";

const Input = styled("input")({
  display: "none",
});

function ProfileAvatar({ avatarPath, updateProfileAvatar, type, large }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const configs = useSelector((state) => state.basicInfoReducer.configs);


  const upload = async (e) => {
    setLoading(true);
    setError(null);

    let file = e.target.files[0];

    const picBase64 = await convertToBase64(file);
    dispatch(uploadAvatar({ type: "profile", picBase64 }))
      .then((res) => {
        setLoading(false);
        updateProfileAvatar(res.picUrl);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: { xs: "space-between", md: "flex-start" },
        alignItems: "center",
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <Avatar
          src={
            avatarPath
              ? `${REACT_APP_API_URL}/${avatarPath}`
              : type === "supplier"
              ? CompanyPlaceholder
              : type === "activity"
              ? ActivityPlaceholder
              : null
          }
          sx={
            large
              ? { width: "150px", height: "150px" }
              : { width: "98px", height: "98px" }
          }
        />
      )}

      <label htmlFor="contained-button-file">
        <Input
          accept=".jpeg, .png, .jpg"
          id="contained-button-file"
          type="file"
          onChange={upload}
        />
        <Button
          variant="text"
          component="span"
          color="info"
          sx={{ ml: { xs: 0, md: "50px" } }}
        >
          {type === "supplier"
            ? t("change_logo")
            : type === "activity"
            ? t("change_image")
            : t("change_avatar")}
        </Button>
      </label>

      {error && (
        <Typography color={"error"}>
          {t(error)} - max {configs?.uploadPicMaxsize} kB
        </Typography>
      )}
    </Box>
  );
}

export default ProfileAvatar;
