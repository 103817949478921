import {
  GET_INTEREST,
  CREATE_INTEREST,
  UPDATE_INTEREST,
  DELETE_INTEREST,
  GET_INTEREST_GROUPS,
  CREATE_INTEREST_GROUPS,
  UPDATE_INTEREST_GROUPS,
  DELETE_INTEREST_GROUPS,
  GET_INITIAL,
  GET_CITY_LIST,
  GET_CONFIGS,
  SET_CONFIGS,
  GET_DASHBOARD_DATA,
} from "./types";
import axios from "axios";
import { setNotif } from "./notifActions";
import { REACT_APP_API_URL } from "../../assets/constants";

// Get initial data
export const getInitial = () => {
  return async (dispatch, state) => {
    const token = state().authReducer.accessToken;

    return axios({
      url: `${REACT_APP_API_URL}/public/get-initial`,
      method: "POST",
      //   data: data,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        dispatch({
          type: GET_INITIAL,
          payload: response.data,
        });
        return Promise.resolve(response.data);
      })
      .catch((error, res) => {
        dispatch(
          setNotif({ message: error.response.data.err, variant: "error" })
        );
        return Promise.reject(error.response.data.err);
      });
  };
};

// Set interest group
export const setInterestGroup = (data) => {
  return async (dispatch, state) => {
    const token = state().authReducer.accessToken;

    return axios({
      url: `${REACT_APP_API_URL}/interestgroup/set`,
      method: "POST",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        dispatch({
          type: data.id ? UPDATE_INTEREST_GROUPS : CREATE_INTEREST_GROUPS,
          payload: data,
        });
        dispatch(setNotif({ message: response.data.msg, variant: "success" }));
        return Promise.resolve(response.data);
      })
      .catch((error, res) => {
        dispatch(
          setNotif({ message: error.response.data.err, variant: "error" })
        );
        return Promise.reject(error.response.data.err);
      });
  };
};

// Get interest group
export const getInterestGroup = () => {
  return async (dispatch, state) => {
    const token = state().authReducer.accessToken;

    return axios({
      url: `${REACT_APP_API_URL}/interestgroup/get`,
      method: "POST",
      //   data: data,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        dispatch({
          type: GET_INTEREST_GROUPS,
          payload: response.data.list,
        });
        return Promise.resolve(response.data);
      })
      .catch((error, res) => {
        dispatch(
          setNotif({ message: error.response.data.err, variant: "error" })
        );
        return Promise.reject(error.response.data.err);
      });
  };
};

// Delete interest group
export const deleteInterestGroup = (data) => {
  return async (dispatch, state) => {
    const token = state().authReducer.accessToken;

    return axios({
      url: `${REACT_APP_API_URL}/interestgroup/del`,
      method: "POST",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        dispatch({
          type: DELETE_INTEREST_GROUPS,
          payload: data,
        });
        dispatch(setNotif({ message: response.data.msg, variant: "success" }));
        return Promise.resolve(response.data);
      })
      .catch((error, res) => {
        dispatch(
          setNotif({ message: error.response.data.err, variant: "error" })
        );
        return Promise.reject(error.response.data.err);
      });
  };
};

// Set interest
export const setInterest = (data) => {
  return async (dispatch, state) => {
    const token = state().authReducer.accessToken;

    return axios({
      url: `${REACT_APP_API_URL}/interest/set`,
      method: "POST",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        dispatch({
          type: data.id ? UPDATE_INTEREST : CREATE_INTEREST,
          payload: data,
        });
        dispatch(setNotif({ message: response.data.msg, variant: "success" }));
        return Promise.resolve(response.data);
      })
      .catch((error, res) => {
        dispatch(
          setNotif({ message: error.response.data.err, variant: "error" })
        );
        return Promise.reject(error.response.data.err);
      });
  };
};

// Get interests
export const getInterests = () => {
  return async (dispatch, state) => {
    const token = state().authReducer.accessToken;

    return axios({
      url: `${REACT_APP_API_URL}/interest/get`,
      method: "POST",
      //   data: data,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        dispatch({
          type: GET_INTEREST,
          payload: response.data.list,
        });
        return Promise.resolve(response.data);
      })
      .catch((error, res) => {
        dispatch(
          setNotif({ message: error.response.data.err, variant: "error" })
        );
        return Promise.reject(error.response.data.err);
      });
  };
};

// Delete interest
export const deleteInterest = (data) => {
  return async (dispatch, state) => {
    const token = state().authReducer.accessToken;

    return axios({
      url: `${REACT_APP_API_URL}/interest/del`,
      method: "POST",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        dispatch({
          type: DELETE_INTEREST,
          payload: data,
        });
        dispatch(setNotif({ message: response.data.msg, variant: "success" }));
        return Promise.resolve(response.data);
      })
      .catch((error, res) => {
        dispatch(
          setNotif({ message: error.response.data.err, variant: "error" })
        );
        return Promise.reject(error.response.data.err);
      });
  };
};

// Get city list
export const getCityList = (data) => {
  // console.log(data);
  return async (dispatch, state) => {
    const token = state().authReducer.accessToken;

    return axios({
      url: `${REACT_APP_API_URL}/public/get-city`,
      method: "POST",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        dispatch({
          type: GET_CITY_LIST,
          payload: response.data.list,
        });
        return Promise.resolve(response.data);
      })
      .catch((error, res) => {
        dispatch(
          setNotif({ message: error.response.data.err, variant: "error" })
        );
        return Promise.reject(error.response.data.err);
      });
  };
};

export const getConfigs = () => {
  return async (dispatch, state) => {
    const token = state().authReducer.accessToken;

    return axios({
      url: `${REACT_APP_API_URL}/config/get`,
      method: "POST",
      //   data: data,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        dispatch({
          type: GET_CONFIGS,
          payload: response.data,
        });
        return Promise.resolve(response.data);
      })
      .catch((error, res) => {
        dispatch(
          setNotif({ message: error.response.data.err, variant: "error" })
        );
        return Promise.reject(error.response.data.err);
      });
  };
};

export const setConfigs = (data) => {
  return async (dispatch, state) => {
    const token = state().authReducer.accessToken;

    return axios({
      url: `${REACT_APP_API_URL}/config/set`,
      method: "POST",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        dispatch({
          type: SET_CONFIGS,
          payload: data,
        });
        dispatch(setNotif({ message: response.data.msg, variant: "success" }));
        return Promise.resolve(response.data);
      })
      .catch((error, res) => {
        dispatch(
          setNotif({ message: error.response.data.err, variant: "error" })
        );
        return Promise.reject(error.response.data.err);
      });
  };
};

export const getDashboardData = (data) => {
  return async (dispatch, state) => {
    const token = state().authReducer.accessToken;
    const url =
      data.type === "admin"
        ? `${REACT_APP_API_URL}/dashboard/get-admin`
        : `${REACT_APP_API_URL}/dashboard/get-supplier`;

    return axios({
      url: url,
      method: "POST",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        dispatch({
          type: GET_DASHBOARD_DATA,
          payload: response.data,
        });
        return Promise.resolve(response.data);
      })
      .catch((error, res) => {
        dispatch(
          setNotif({ message: error.response.data.err, variant: "error" })
        );
        return Promise.reject(error.response.data.err);
      });
  };
};
