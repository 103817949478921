import { GET_BADGES, GET_NOTIFICATIONS } from "./types";
import axios from "axios";
import { setNotif } from "./notifActions";
import { REACT_APP_API_URL } from "../../assets/constants";

export const getNotifications = (data) => {
  return async (dispatch, state) => {
    const token = state().authReducer.accessToken;

    return axios({
      url: `${REACT_APP_API_URL}/notification/get-list`,
      method: "POST",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        dispatch({
          type: GET_NOTIFICATIONS,
          payload: response.data,
        });
        return Promise.resolve();
      })
      .catch((error, res) => {
        dispatch(
          setNotif({ message: error.response.data.err, variant: "error" })
        );
        return Promise.reject(error.response.data.err);
      });
  };
};

export const getBadges = (data) => {
  return async (dispatch, state) => {
    const token = state().authReducer.accessToken;

    return axios({
      url: `${REACT_APP_API_URL}/notification/get-badges`,
      method: "POST",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        dispatch({
          type: GET_BADGES,
          payload: response.data,
        });
        return Promise.resolve();
      })
      .catch((error, res) => {
        dispatch(
          setNotif({ message: error.response.data.err, variant: "error" })
        );
        return Promise.reject(error.response.data.err);
      });
  };
};
