import React from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";
import logo from "../../../assets/images/top-logo.png";

import Toolbar from "@mui/material/Toolbar";
import SideMenuLinks from "./SideMenuLinks";
import LanguageButton from "../../../components/basic/LanguageButton";
const drawerWidth = 272;

const SideMenu = () => {
  const location = useLocation();

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        // height: "100vh",
        flexShrink: 0,
        // backgroundColor: "yellow",
        pb: 1,
        display: { xs: "none", md: "flex" },
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth - 8,
          height: `calc(100% - 16px)`,
          boxSizing: "border-box",
          backgroundColor: "background",
          borderRadius: "5px",
          border: "none",
          my: 1,
          ml: 1,
          // bottom: "8px",
          // height: "100vh",
          boxShadow: "-5px 0px 30px rgba(0, 0, 0, 0.14)",
        },
      }}
    >
      {/* <Toolbar
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "270px",
        }}
      > */}
      <Box
        sx={{
          backgroundColor: "#e7e7e7",
          py: 1,
          px: 2,
          borderRadius: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // width: "270px",
          m: 1,
          height: "50px",
        }}
      >
        <img
          src={logo}
          alt="logo"
          // style={{
          //   height: 45,
          // }}
        />
      </Box>
      {/* </Toolbar> */}
      <Box sx={{ overflow: "auto", pt: "15px" }}>
        <SideMenuLinks currentPath={location.pathname} />
      </Box>
      <Box sx={{ alignSelf: "center", bottom: "20px", position: "absolute" }}>
        <LanguageButton />
      </Box>
    </Drawer>
  );
};
export default SideMenu;
