import {
  CREATE_TICKET,
  CREATE_TICKET_REPLY,
  GET_TICKETS,
  GET_TICKET_MESSAGES,
  UPDATE_TICKET_STATUS,
  DELETE_TICKET,
  CLEAR_TICKET_STORE,
} from "../actions/types";

const INITIAL_STATE = {
  tickets: [],
  ticketDetails: null,
};

const ticketReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TICKETS:
      return {
        ...state,
        tickets: action.payload,
      };
    case GET_TICKET_MESSAGES:
      console.log(action.payload);
      return {
        ...state,
        ticketDetails: action.payload,
      };

    case CLEAR_TICKET_STORE:
      return {
        tickets: [],
        ticketDetails: null,
      };
    default:
      return state;
  }
};

export default ticketReducer;
