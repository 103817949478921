import {
  GET_ADMINS,
  GET_INDIVIDUALS,
  GET_SUPPLIERS,
  GET_USER_DETAIL,
  CLEAN_USER_MANAGEMENT_STORE,
  CLEAR_CURRENT_USER_DETAIL,
} from "../actions/types";

const INITIAL_STATE = {
  admins: [],
  individuals: [],
  suppliers: [],
  userDetail: null,
};

const userManagementReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ADMINS:
      return {
        ...state,
        admins: action.payload,
      };
    case GET_SUPPLIERS:
      return {
        ...state,
        suppliers: action.payload,
      };

    case GET_INDIVIDUALS:
      return {
        ...state,
        individuals: action.payload,
      };
    case GET_USER_DETAIL:
      return {
        ...state,
        userDetail: action.payload,
      };
    case CLEAR_CURRENT_USER_DETAIL:
      return {
        ...state,
        userDetail: null,
      };
    case CLEAN_USER_MANAGEMENT_STORE:
      return {
        admins: [],
        individuals: [],
        suppliers: [],
        userDetail: null,
      };
    default:
      return state;
  }
};

export default userManagementReducer;
