import {
  GET_MY_PROFILE,
  LOGIN,
  LOGOUT,
  REFRESH_TOKEN,
  UPDATE_MY_PROFILE,
  CLEAN_USER_MANAGEMENT_STORE,
  CLEAR_ACTIVITY_STORE,
  CLEAR_AUTH_STORE,
  CLEAR_BASIC_INFO_STORE,
  CLEAR_FOLLOW_STORE,
  CLEAR_NEWS_STORE,
  CLEAR_NOTIFICATION_STORE,
  CLEAR_TICKET_STORE,
} from "./types";
import axios from "axios";
import { setNotif } from "./notifActions";
import { persistor } from "../store";
import { REACT_APP_API_URL } from "../../assets/constants";
import { useLocation, useNavigate } from "react-router-dom";

// Register
export function register(data) {
  return async (dispatch, state) => {
    return axios({
      url: `${REACT_APP_API_URL}/user/register`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return Promise.resolve(response.data.msg);
      })

      .catch((error) => {
        return Promise.reject(error.response.data.err);
      });
  };
}

// Login
export const login = (data) => {
  return async (dispatch, state) => {
    return axios({
      url: `${REACT_APP_API_URL}/user/login-email`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        dispatch({
          type: LOGIN,
          payload: response.data,
        });

        return Promise.resolve(response.data);
      })
      .catch((error, res) => {
        return Promise.reject(error.response.data.err);
      });
  };
};

// Logout
export const logout = (data) => {
  console.log(data);
  return async (dispatch) => {
    return axios({
      url: `${REACT_APP_API_URL}/user/logout`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        // await persistor.purge();
        dispatch(clearState());
        return Promise.resolve();
      })
      .catch((error) => {
        console.log(error.response.data.err);
        dispatch(clearState());

        return Promise.reject();
      });
  };
};

// Logout
export const refreshToken = (data) => {
  return async (dispatch, state) => {
    // const token = state().authReducer.token;
    return axios({
      url: `${REACT_APP_API_URL}/user/login-refresh`,
      method: "POST",
      data: data,
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        dispatch({
          type: REFRESH_TOKEN,
          payload: response.data,
        });
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        localStorage.setItem("refreshToken", "");

        return Promise.reject();
      });
  };
};

export const changePassword = (data) => {
  return async (dispatch, state) => {
    const token = state().authReducer.accessToken;

    return axios({
      url: `${REACT_APP_API_URL}/user/change-pass`,
      method: "POST",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        dispatch(setNotif({ message: response.data.msg, variant: "success" }));
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        dispatch(
          setNotif({ message: error.response.data.err, variant: "error" })
        );

        return Promise.reject();
      });
  };
};

export const sendResetPassword = (data) => {
  return async (dispatch) => {
    return axios({
      url: `${REACT_APP_API_URL}/user/reset-pass-request`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error.response.data.err);
      });
  };
};

export const confirmResetPassword = (data) => {
  return async (dispatch) => {
    return axios({
      url: `${REACT_APP_API_URL}/user/reset-pass-confirm`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        dispatch(setNotif(error.response));
        return Promise.reject();
      });
  };
};

export const sendEmailConfirmRequest = (data) => {
  console.log(data);
  return async (dispatch, state) => {
    const token = state().authReducer.accessToken;
    return axios({
      url: `${REACT_APP_API_URL}/user/validate-email-request`,
      method: "POST",
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.log(error.response);
        dispatch(setNotif(error.response));
        return Promise.reject();
      });
  };
};

export const confirmEmail = (data) => {
  return async (dispatch, state) => {
    // const token = state().authReducer.accessToken;
    return axios({
      url: `${REACT_APP_API_URL}/user/validate-email-confirm`,
      method: "POST",
      data: data,
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.log(error.response);
        // dispatch(setNotif(error.response));
        return Promise.reject();
      });
  };
};

const clearState = () => {
  return async (dispatch) => {
    dispatch({
      type: CLEAN_USER_MANAGEMENT_STORE,
      payload: null,
    });
    dispatch({
      type: CLEAR_ACTIVITY_STORE,
      payload: null,
    });
    dispatch({
      type: CLEAR_BASIC_INFO_STORE,
      payload: null,
    });
    dispatch({
      type: CLEAR_FOLLOW_STORE,
      payload: null,
    });
    dispatch({
      type: CLEAR_NEWS_STORE,
      payload: null,
    });
    dispatch({
      type: CLEAR_NOTIFICATION_STORE,
      payload: null,
    });
    dispatch({
      type: CLEAR_TICKET_STORE,
      payload: null,
    });
    dispatch({
      type: CLEAR_AUTH_STORE,
      payload: null,
    });
    window.history.replaceState({}, document.title);
    localStorage.clear();
  };
};
