import {
  CREATE_NEWS,
  UPDATE_NEWS,
  DELETE_NEWS,
  GET_ADMIN_NEWS,
  GET_SUPPLIER_NEWS,
  GET_NEWS_DETAIL,
  CLEAR_NEWS_STORE,
} from "../actions/types";

const INITIAL_STATE = {
  adminNews: [],
  supplierNews: [],
  newsDetail: null,
};

const userManagementReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ADMIN_NEWS:
      return {
        ...state,
        adminNews: action.payload,
      };
    case GET_SUPPLIER_NEWS:
      return {
        ...state,
        supplierNews: action.payload,
      };
    case GET_NEWS_DETAIL:
      return {
        ...state,
        newsDetail: action.payload,
      };

    case CLEAR_NEWS_STORE:
      return {
        adminNews: [],
        supplierNews: [],
        newsDetail: null,
      };

    default:
      return state;
  }
};

export default userManagementReducer;
