import React, { Suspense, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import ScrollToTop from "./utils/ScrollToTop";
import NotFound from "./views/NotFound";
import myTheme from "./assets/theme";
import Spinner from "./components/basic/Spinner";
import AdminLayout from "./layouts/admin/AdminLayout";
import PrivateRoute from "./components/basic/PrivateRoute";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { CssBaseline } from "@mui/material/";
import { getInitial } from "./redux/actions/basicInfoActions";
import SupplierLayout from "./layouts/supplier/SupplierLayout";
import { getBadges } from "./redux/actions/notificationActions";
import { refreshToken } from "./redux/actions/authActions";
function App() {
  const notifs = useSelector((state) => state.notifReducer.notifs);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authReducer.accessToken);
  const refToken = useSelector((state) => state.authReducer.refreshToken);

  const AdminDashboard = React.lazy(() => import("./views/admin/Dashboard"));
  const InterestGroups = React.lazy(() =>
    import("./views/admin/basicInfo/InterestGroups")
  );
  const Interests = React.lazy(() =>
    import("./views/admin/basicInfo/Interests")
  );
  const Admins = React.lazy(() => import("./views/admin/users/Admins"));
  const Individuals = React.lazy(() =>
    import("./views/admin/users/Individuals")
  );
  const Suppliers = React.lazy(() => import("./views/admin/users/Suppliers"));
  const Activities = React.lazy(() => import("./views/admin/Activities"));
  const AdminReports = React.lazy(() => import("./views/admin/Reports"));

  const News = React.lazy(() => import("./views/admin/news"));
  const Support = React.lazy(() => import("./views/admin/Support"));
  const Settings = React.lazy(() => import("./views/admin/Settings"));

  const SupplierDashboard = React.lazy(() =>
    import("./views/supplier/Dashboard")
  );
  const SupplierSupport = React.lazy(() => import("./views/supplier/Support"));
  const SupplierActivities = React.lazy(() =>
    import("./views/supplier/Activities")
  );
  const SupplierFollowers = React.lazy(() =>
    import("./views/supplier/Followers")
  );
  const SupplierInvitation = React.lazy(() =>
    import("./views/supplier/Invitation")
  );
  const SupplierMyProfile = React.lazy(() =>
    import("./views/supplier/MyProfile")
  );
  const SupplierNews = React.lazy(() => import("./views/supplier/news"));
  const SupplierReports = React.lazy(() => import("./views/supplier/Reports"));

  const Auth = React.lazy(() => import("./views/auth/Auth"));
  const ResetPassword = React.lazy(() => import("./views/auth/ResetPassword"));
  const ConfirmEmail = React.lazy(() => import("./views/auth/ConfirmEmail"));

  useEffect(() => {
    if (notifs.length > 0) {
      const translatedMessage = t(notifs.at(-1).message);
      enqueueSnackbar(translatedMessage, {
        variant: notifs.at(-1).variant ? notifs.at(-1).variant : "error",
      });
    }
  }, [notifs.length]);

  useEffect(() => {
    let timer1 = null;
    let timer2 = null;
    if (token) {
      dispatch(getInitial());
      dispatch(getBadges());

      timer1 = setInterval(() => {
        dispatch(getBadges());
      }, 60 * 1000);

      timer2 = setInterval(() => {
        dispatch(refreshToken({ refreshToken: refToken }));
      }, 25 * 60 * 1000);

      return () => {
        clearInterval(timer1);
        clearInterval(timer2);
      };
    } else if (!!timer1) {
      clearInterval(timer1);
      clearInterval(timer2);
    }
  }, [token]);

  return (
    <ThemeProvider theme={myTheme}>
      <CssBaseline />
      <Suspense fallback={<Spinner />}>
        <ScrollToTop>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/">
              <Route path="" element={<Auth />} />
              <Route
                path="reset-password/:email/:token"
                element={<ResetPassword />}
              />
              <Route
                path="validate-email/:email/:token"
                element={<ConfirmEmail />}
              />
            </Route>
            <Route path="/admin" element={<AdminLayout />}>
              <Route
                path=""
                element={
                  <PrivateRoute role={"adm"}>
                    <AdminDashboard />
                  </PrivateRoute>
                }
              />

              <Route
                path="basic-info/interest-group"
                element={
                  <PrivateRoute role={"adm"}>
                    <InterestGroups />
                  </PrivateRoute>
                }
              />
              <Route
                path="basic-info/interests"
                element={
                  <PrivateRoute role={"adm"}>
                    <Interests />
                  </PrivateRoute>
                }
              />
              <Route
                path="users/individuals"
                element={
                  <PrivateRoute role={"adm"}>
                    <Individuals />
                  </PrivateRoute>
                }
              />
              <Route
                path="users/suppliers"
                element={
                  <PrivateRoute role={"adm"}>
                    <Suppliers />
                  </PrivateRoute>
                }
              />
              <Route
                path="users/admins"
                element={
                  <PrivateRoute role={"adm"}>
                    <Admins />
                  </PrivateRoute>
                }
              />
              <Route
                path="activities"
                element={
                  <PrivateRoute role={"adm"}>
                    <Activities />
                  </PrivateRoute>
                }
              />
              <Route
                path="news"
                element={
                  <PrivateRoute role={"adm"}>
                    <News />
                  </PrivateRoute>
                }
              />

              <Route
                path="reports"
                element={
                  <PrivateRoute role={"adm"}>
                    <AdminReports />
                  </PrivateRoute>
                }
              />

              <Route
                path="support"
                element={
                  <PrivateRoute role={"adm"}>
                    <Support />
                  </PrivateRoute>
                }
              />

              <Route
                path="settings"
                element={
                  <PrivateRoute role={"adm"}>
                    <Settings />
                  </PrivateRoute>
                }
              />
            </Route>

            <Route path="/supplier" element={<SupplierLayout />}>
              <Route
                path=""
                element={
                  <PrivateRoute role={"sup"}>
                    <SupplierDashboard />
                  </PrivateRoute>
                }
              />

              <Route
                path="followers"
                element={
                  <PrivateRoute role={"sup"}>
                    <SupplierFollowers />
                  </PrivateRoute>
                }
              />
              <Route
                path="activities"
                element={
                  <PrivateRoute role={"sup"}>
                    <SupplierActivities />
                  </PrivateRoute>
                }
              />
              <Route
                path="news"
                element={
                  <PrivateRoute role={"sup"}>
                    <SupplierNews />
                  </PrivateRoute>
                }
              />
              <Route
                path="reports"
                element={
                  <PrivateRoute role={"sup"}>
                    <SupplierReports />
                  </PrivateRoute>
                }
              />

              <Route
                path="invitation"
                element={
                  <PrivateRoute role={"sup"}>
                    <SupplierInvitation />
                  </PrivateRoute>
                }
              />

              <Route
                path="profile"
                element={
                  <PrivateRoute role={"sup"}>
                    <SupplierMyProfile />
                  </PrivateRoute>
                }
              />

              <Route
                path="support"
                element={
                  <PrivateRoute role={"sup"}>
                    <SupplierSupport />
                  </PrivateRoute>
                }
              />
            </Route>
          </Routes>
        </ScrollToTop>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
