import React, { useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTranslation } from "react-i18next";
import logo from "../../../assets/images/top-logo.png";
import Colors from "../../../assets/Colors";
import { Collapse } from "@mui/material";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { useNavigate, useLocation } from "react-router-dom";
import adminMenuItems from "../adminMenuItems";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function DrawerLinks(props) {
  const { currentPath, toggleDrawer } = props;
  const { t } = useTranslation();
  const [selectedMenu, setSelectedMenu] = useState(null);
  const location = useLocation();
  let navigate = useNavigate();

  const goTo = (menuItem) => {
    if (menuItem.label === selectedMenu) {
      setSelectedMenu(null);
    } else {
      setSelectedMenu(menuItem.label);
      if (!menuItem.nestedMenu.length) {
        navigate(menuItem.path);
      }
    }
  };

  // useEffect(() => {
  //   const curPath = location.pathname.replace(/\/$/, "");

  //   const currentItem = adminMenuItems.find((item) => item.path === curPath);
  //   console.log(location);

  //   console.log(curPath);
  //   console.log(currentItem.label);
  //   setSelectedMenu(currentItem.label);
  // }, []);

  return (
    <Box sx={{ minHeight: "100vh", flex: 1 }}>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mx: 2, my: 1 }}
        >
          <img src={logo} alt="logo" style={{ height: 30 }} />
        </Stack>

        <List>
          {adminMenuItems.map((item, index) => (
            <>
              <ListItemButton
                selected={currentPath === item.path}
                key={index}
                onClick={props.toggleDrawer(
                  "top",
                  item.nestedMenu.length > 0,
                  () => goTo(item)
                )}
                sx={{ minHeight: "56px" }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={t(item.label)}
                  sx={{ color: "text.secondary" }}
                />
                {item.nestedMenu.length > 0 && (
                  <ListItemIcon sx={{ justifyContent: "flex-end" }}>
                    {selectedMenu === item.label ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </ListItemIcon>
                )}
              </ListItemButton>

              {item.nestedMenu.length > 0 ? (
                <Collapse
                  in={selectedMenu === item.label}
                  timeout="auto"
                  unmountOnExit
                >
                  {item.nestedMenu.map((subItem, index) => (
                    <ListItemButton
                      selected={currentPath === subItem.path}
                      key={index}
                      onClick={props.toggleDrawer("top", false, () =>
                        goTo(subItem)
                      )}
                      sx={{ height: "56px" }}
                    >
                      <ListItemText
                        primary={t(subItem.label)}
                        sx={{ color: "text.secondary", pl: "40px" }}
                      />
                    </ListItemButton>
                  ))}
                </Collapse>
              ) : null}
            </>
          ))}
        </List>
      </Box>
    </Box>
  );
}

export default DrawerLinks;
