import {
  GET_CHAT_LIST,
  GET_MESSAGE_HISTORY,
  SEND_MESSAGE,
  GET_CHAT_LIST_LIVE,
  GET_MESSAGE,
  SET_MUTE,
  SET_DISABLE,
  CLEAR_CHAT_STORE,
} from '../actions/types';

// const test = store.getState();

const INITIAL_STATE = {
  chatList: {list: []},
  messages: {list: [], chat: null},
};

const chatReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CHAT_LIST:
      return {
        ...state,
        chatList: action.payload,
      };

    case GET_CHAT_LIST_LIVE:
      let updatedChatList = [];
      let filteredChatList = [];
      if (action.payload.list.length > 0) {
        filteredChatList = state.chatList.list.filter(
          item => !action.payload.list.some(i => i.id === item.id),
        );
      }

      console.log(filteredChatList);
      updatedChatList = [...action.payload.list, ...filteredChatList];
      return {
        ...state,
        chatList: {
          ...state.chatList,
          list: updatedChatList,
        },
      };

    case GET_MESSAGE_HISTORY:
      return {
        ...state,
        messages: {
          ...state.messages,
          list: action.payload.messages,
          chat: action.payload.chat,
          users: action.payload.users,
          paginate: action.payload.paginate,
        },
      };

    case SEND_MESSAGE:
      return {
        ...state,
        messages: {
          ...state.messages,
          list: [action.payload, ...state.messages.list],
        },
      };
    case GET_MESSAGE:
      const updatedList2 = [...action.payload.messages, ...state.messages.list];
      updatedList2.forEach(item => {
        if (
          action.payload.seenMessage > 1 &&
          item.id <= action.payload.seenMessage
        ) {
          item.seenDate = 1;
        }
      });
      return {
        ...state,
        messages: {
          ...state.messages,
          list: updatedList2,
        },
      };
    case SET_MUTE:
      const currentChat = state.chatList.list.find(
        item => item.id === state.messages.chat.id,
      );
      currentChat.isMute = action.payload;
      const updatedList = state.chatList.list.filter(
        item => item.id !== state.messages.chat.id,
      );
      return {
        ...state,
        chatList: {
          ...state.chatList,
          list: [currentChat, ...updatedList],
        },
        messages: {
          ...state.messages,
          chat: {
            ...state.messages.chat,
            isMute: action.payload,
          },
        },
      };

    case SET_DISABLE:
      const currentChat1 = state.chatList.list.find(
        item => item.id === state.messages.chat.id,
      );
      console.log(currentChat1);
      currentChat1.disabled = action.payload;
      const updatedList1 = state.chatList.list.filter(
        item => item.id !== state.messages.chat.id,
      );
      return {
        ...state,
        chatList: {
          ...state.chatList,
          list: [currentChat1, ...updatedList1],
        },
        messages: {
          ...state.messages,
          chat: {
            ...state.messages.chat,
            disabled: action.payload,
          },
        },
      };

    case CLEAR_CHAT_STORE:
      return {
        chatList: {list: []},
        messages: {list: [], chat: null},
      };

    default:
      return state;
  }
};

export default chatReducer;
