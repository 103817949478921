import GroupIcon from "@mui/icons-material/Group";
import DashboardIcon from "@mui/icons-material/Dashboard";
import InfoIcon from "@mui/icons-material/Info";
import CelebrationIcon from "@mui/icons-material/Celebration";
import FeedIcon from "@mui/icons-material/Feed";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SupportIcon from "@mui/icons-material/Support";
import SettingsIcon from "@mui/icons-material/Settings";
const adminMenuItems = [
  {
    id: 0,
    label: "dashboard",
    path: "/admin",
    nestedMenu: [],
    icon: <DashboardIcon />,
  },
  {
    id: 2,
    label: "basic_info",
    path: "/admin/basic-info",
    icon: <InfoIcon />,

    nestedMenu: [
      {
        id: 0,
        label: "interest_groups",
        path: "/admin/basic-info/interest-group",
        nestedMenu: [],
      },
      {
        id: 1,
        label: "interests",
        path: "/admin/basic-info/interests",
        nestedMenu: [],
      },
    ],
  },

  {
    id: 3,
    label: "users",
    path: "/admin/users",
    icon: <GroupIcon />,
    nestedMenu: [
      {
        id: 0,
        label: "individuals",
        path: "/admin/users/individuals",
        nestedMenu: [],
      },
      {
        id: 1,
        label: "suppliers",
        path: "/admin/users/suppliers",
        nestedMenu: [],
      },
      {
        id: 2,
        label: "admins",
        path: "/admin/users/admins",
        nestedMenu: [],
      },
    ],
  },
  {
    id: 4,
    label: "activities",
    path: "/admin/activities",
    nestedMenu: [],
    icon: <CelebrationIcon />,
  },
  {
    id: 5,
    label: "news",
    path: "/admin/news",
    nestedMenu: [],
    icon: <FeedIcon />,
  },

  {
    id: 6,
    label: "support",
    path: "/admin/support",
    nestedMenu: [],
    icon: <SupportIcon />,
  },
  {
    id: 7,
    label: "reports",
    path: "/admin/reports",
    nestedMenu: [],
    icon: <ListAltIcon />,
  },
  {
    id: 8,
    label: "settings",
    path: "/admin/settings",
    nestedMenu: [],
    icon: <SettingsIcon />,
  },
];

export default adminMenuItems;
